import { createEditor, Transforms, Element } from 'slate';



export  const htmlToNode = async(htmlCode)=> {
  function  HtmlToslateFragment(html) {
    const editor = createEditor();
    const fragment = deserializeHTML(editor, html);
    return fragment;
  }

  function deserializeHTML(editor, html) {
    const parsed = new DOMParser().parseFromString(html, 'text/html');
    const fragment = deserializeElements(editor, parsed.body.childNodes);
    return fragment;
  }

  function deserializeElements(editor, elements) {
    const fragment = [];

    for (const element of elements) {
      if (element.nodeType === Node.TEXT_NODE) {
        if (element.textContent.trim() !== '') {
          fragment.push({ text: element.textContent });
        }
      } else if (element.nodeType === Node.ELEMENT_NODE) {
        const node = deserializeElement(editor, element);
        if (node) {
          fragment.push(node);
        }
      }
    }

    return fragment;
  }

  function deserializeElement(editor, element) {
    const type = element.nodeName.toLowerCase();
    const children = deserializeElements(editor, element.childNodes);

    if (type === 'strong') {
      return {
        type: 'paragraph',
        children: [{ text: element.textContent, bold: true }],
      };
    } else if (type === 'p') {
      return { type: 'paragraph', children };
    } else if (type === 'em') {
      return {
        type: 'paragraph',
        children: [{ text: element.textContent, italic: true }],
      };
    } else if (type === 'u') {
      return {
        type: 'paragraph',
        children: [{ text: element.textContent, underline: true }],
      };
    } else if (type === 's') {
      return {
        type: 'paragraph',
        children: [{ text: element.textContent, strikethrough: true }],
      };
    }

    return null;
  }



  const slateFragment = await  HtmlToslateFragment(htmlCode);
  
  const flattenedFragment = await  flattenFragment(slateFragment);

  return flattenedFragment;
}


async function flattenFragment(fragment)  { 
  const flattened = [];
function flattenFragmentbody (fragment) {
 
  
  for (const node of fragment) {
    if (node.children) {
      // flattened.push({ type: node.type, children: flattenFragmentbody(node.children) });
      flattenFragmentbody(node.children)
    }
     else {
    
      flattened.push(node);

      
    }
  }


  
 
}
await flattenFragmentbody(fragment)

return flattened;

}