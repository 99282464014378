import { useEffect, useRef, useState } from "react";

const SelectComponent = (props) => {
	const [show, setShow] = useState(false);
	const [value, setValue] = useState([]);
	const options = props.element.children[0].options;
	const componentRef = useRef(null);

	useEffect(() => {
		setShow(true);
	}, []);

	const handleChange = (option) => {
		if(value.includes(option)){
			setValue(prev => prev.filter((i) => i !== option));
		}else{
			const val = options.filter((i) => value.includes(i) || i === option); //this line is to keep the sequence of the options
			setValue(val); 
		}
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
		  if (componentRef.current && !componentRef.current.contains(event.target)) {
			setShow(false);
		  }
		};
	
		const handleKeyPressOutside = (event) => {
		  if (event.key === 'Escape') {
			setShow(false);
		  }
		};
	
		document.addEventListener('mousedown', handleClickOutside);
		document.addEventListener('keydown', handleKeyPressOutside);
	
		return () => {
		  document.removeEventListener('mousedown', handleClickOutside);
		  document.removeEventListener('keydown', handleKeyPressOutside);
		};
	}, []);
  
	return (
		<div ref={componentRef} className={`select-elements`}>
			{show && options.map((option, index) => {
				return (
					option.length > 0 && 
					(
						<span key={index} value={option} onClick={(e) => handleChange(option)} className={`${value.includes(option) ? 'selected' : ''} option`}>
							{option}  
						</span>
					)
				)
			})}
			{
				!show && value.length === 0 &&
				<span onClick={(e) => setShow(true)} className={`option`}>
					Edit
				</span>
			}
			{!show && Array.isArray(value) && value.map((option, index) => {
				return (
					option.length > 0 && 
					(
						<span key={index} value={option} onClick={() => {setShow(true)}}>
							{option}{value.length - 1 > index ? ', ' : ''}
						</span>
					)
				)
			})}
		</div>
	);
};

export default SelectComponent;